import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import Dashboard from "../src/Pages/Dashboard";
import ComplaintRegistration from "../src/Pages/ComplaintRegistration";
import TransactionSearch from "../src/Pages/TransactionSearch";
import OnboardingStep from "../src/Pages/OnboardingStep";
import PayEmi from "../src/Pages/PayEmi";
import AllBanks from "./Pages/AllBanks";
import HomeBankPayEmi from "./Pages/HomeBankPayEmi";
import PaymentconfirmBankPayEmi from "./Pages/PaymentConfirmBankPayEmi";
import SignIn from "../src/Pages/SignIn";
import PaymentConfirmation from "../src/Pages/PaymentConfirmation";
import SubDomainPage from "./Pages/SubDomainPage";
import PayEmiSuccess from "./Pages/PayEmiSuccess";
import Splash from "../src/Pages/Splash";
import Category from "./Pages/Category";
import BillPayCategory from "./Pages/BillPayCategory";
import GetBankDetail from "./Pages/BankDetail";
import PayEmiTransPage from "./Pages/PayEmiTransPage";
import Notification from "../src/Pages/Notification";
import RegisterComplaint from "../src/Pages/RegisterComplaint";
import ComplaintTracking from "../src/Pages/ComplaintTracking";
import SubdomainPayemSuccess from "./Pages/SubdomainPayemSuccess";
import VerifyMobileNumber from "./Pages/VerifyMobileNumber";
import PayemiFailure from "./Pages/PayemiFailure";
import PayNow from "./Pages/PayNow";
import PayNowBillFetch from "./Pages/PayNowBillFetch";
import ReactGa from "react-ga";
import Profile from "./Pages/Profile";
import AddCard from "./Pages/AddCard";
import { withRouter } from "react-router-dom";
import PayemiProcessing from "./Pages/PayemiProcessing";
import SubDomainPayemiFailure from "./Pages/SubDomainPayFailure";
import Billers from "./Pages/Billers";
import DomainPayEmiProcessing from "./Pages/DomainPayEmiProcessing";
import About from "./Pages/Basic/About";
import PrivacyPolicy from "./Pages/Basic/PrivacyPolicy";
import PayEmiTransPaid from "./Pages/PayemiTransPaid";
import PayEmiTransFailed from "./Pages/PayemiTransFailed";
import PayemiTransProcess from "./Pages/PayemiTransProcess";
import Refund from "./Pages/Basic/Refund";
import Conditions from "./Pages/Basic/Conditions";
import Footer from "./Components/Footer";
import "react-loading-skeleton/dist/skeleton.css";
import PayEmiRedirect from "./Pages/PayEmiRedirect";

/* Components */

function App() {
    const [subdomain, setSubDomain] = useState(null);
    const accessToken = localStorage.getItem("access_token");
    const [loading, setLoaing] = useState(true);
    // subDoamin useeffect

    useEffect(() => {
        const host = window.location.host;
        const sub = host.split(".").slice(0, host.includes("localhost") ? -1 : -2);
        if (sub.length > 0) {
            setSubDomain(sub[0]);
            setLoaing(false);
        }
        setLoaing(false);
    }, [subdomain]);
    let subDom = subdomain;

    let location = useLocation();
    React.useEffect(() => {
        ReactGa.pageview(location.pathname);
    }, [location]);

    const pn = location.pathname;
    let pnstr = pn.substring(1);

    useEffect(() => {
        document.title = pnstr;
    }, [pn]);
    return (
        <div className="App d-flex flex-column min-h-screen justify-content-between p-0 m-0">
            {loading ? (
                <></>
            ) : (
                <>
                    <div>
                        <Switch>
                            <Route exact path="/sign-in">
                                <SignIn />
                            </Route>
                            <Route exact path="/verify-otp/:number">
                                <VerifyMobileNumber />
                            </Route>
                            <Route exact path="/">
                                <Splash />
                            </Route>
                            <Route exact path="/about-us">
                                <About />
                            </Route>
                            <Route exact path="/privacy-policy">
                                <PrivacyPolicy />
                            </Route>
                            <Route exact path="/refund-and-cancellation">
                                <Refund />
                            </Route>
                            <Route exact path="/terms-and-conditions">
                                <Conditions />
                            </Route>
                            <Route exact path="/:id">
                                <PayEmiRedirect source="sms" />
                            </Route>
                            <Route exact path="/s/:id">
                                <PayEmiRedirect source="sms" />
                            </Route>
                            <Route exact path="/w/:id">
                                <PayEmiRedirect source="whatsapp" />
                            </Route>

                            {subDom && (
                                <>
                                    {pn !== "/processing" && pn !== "/success" && (
                                        <>
                                            <Route exact path="/:loanNumber/:amount">
                                                <SubDomainPage data={subdomain} setSubDomain={setSubDomain} />
                                            </Route>
                                        </>
                                    )}
                                    <Route exact path="/success">
                                        <SubdomainPayemSuccess subdomain={subdomain} />
                                    </Route>
                                    <Route exact path="/failure">
                                        <SubDomainPayemiFailure />
                                    </Route>
                                    <Route exact path="/processing">
                                        <PayemiProcessing />
                                    </Route>
                                </>
                            )}
                            {!accessToken ? (
                                <Redirect to="/sign-in" />
                            ) : (
                                <>
                                    <Route exact path="/payment-confirmation">
                                        <PaymentConfirmation />
                                    </Route>
                                    {/*namrata start */}
                                    <Route exact path="/home">
                                        <HomeBankPayEmi />
                                    </Route>
                                    <Route exact path="/category">
                                        <Category />
                                    </Route>
                                    <Route exact path="/billers">
                                        <Billers />
                                    </Route>
                                    <Route exact path="/biller/:billerId">
                                        <GetBankDetail />
                                    </Route>
                                    <Route exact path="/dashboard">
                                        <PayNow />
                                    </Route>
                                    <Route exact path="/payment-confirm-bank">
                                        <PaymentconfirmBankPayEmi />
                                    </Route>
                                    <Route exact path="/Pay-Now-billfetch">
                                        <PayNowBillFetch />
                                    </Route>
                                    <Route exact path="/transaction-history">
                                        <PayEmiTransPage />
                                    </Route>
                                    <Route exact path="/transaction-Paid">
                                        <PayEmiTransPaid />
                                    </Route>
                                    <Route exact path="/transaction-Failed">
                                        <PayEmiTransFailed />
                                    </Route>
                                    <Route exact path="/transaction-Process">
                                        <PayemiTransProcess />
                                    </Route>
                                    {/*namrata end */}
                                    <Route exact path="/category/:id">
                                        <BillPayCategory />
                                    </Route>
                                    <Route exact path="/dashboard1">
                                        <Dashboard />
                                    </Route>
                                    <Route exact path="/pay-emi">
                                        <PayEmi />
                                    </Route>
                                    <Route exact path="/processing">
                                        <DomainPayEmiProcessing />
                                    </Route>
                                    <Route exact path="/success">
                                        <PayEmiSuccess />
                                    </Route>
                                    <Route exact path="/failure">
                                        <PayemiFailure />
                                    </Route>
                                    <Route exact path="/complaint-registration">
                                        <ComplaintRegistration />
                                    </Route>
                                    <Route exact path="/transaction-search">
                                        <TransactionSearch />
                                    </Route>
                                    <Route exact path="/onboarding-step">
                                        <OnboardingStep />
                                    </Route>
                                    <Route exact path="/all-bank">
                                        <AllBanks />
                                    </Route>

                                    <Route exact path="/register-complaint">
                                        <RegisterComplaint />
                                    </Route>
                                    <Route exact path="/complaint-tracking">
                                        <ComplaintTracking />
                                    </Route>
                                    <Route exact path="/profile">
                                        <Profile />
                                    </Route>
                                    <Route exact path="/notification">
                                        <Notification />
                                    </Route>
                                    <Route exact path="/add-card">
                                        <AddCard />
                                    </Route>
                                </>
                            )}
                        </Switch>
                    </div>

                    {pn !== "/" && pn.indexOf("/s/") <= -1 && <Footer />}
                </>
            )}
        </div>
    );
}

export default withRouter(App);
